import {Octicon, OcticonSvgs} from './command-palette-page-stack-element'
import {attr, controller} from '@github/catalyst'
import {ClientDefinedProviderElement} from '../../assets/modules/github/command-palette/client-defined-provider-element'
import CommandPalette from '../../assets/modules/github/command-palette/command-palette-element'
import {CommandPaletteItemElement} from '../../assets/modules/github/command-palette/command-palette-item-element'
import {CommandPaletteItemGroupElement} from '../../assets/modules/github/command-palette/command-palette-item-group-element'
import {Item} from '../../assets/modules/github/command-palette/item'
import {PrefetchedProvider} from '../../assets/modules/github/command-palette/providers/prefetched-provider'
import {ProviderElement} from '../../assets/modules/github/command-palette/provider-element'
import {Query} from '../../assets/modules/github/command-palette/query'
import {Scope} from '../../assets/modules/github/command-palette/command-palette-scope-element'
import {ServerDefinedProviderElement} from '../../assets/modules/github/command-palette/server-defined-provider-element'

export interface PageProps {
  title?: string
  queryText?: string
  mode?: string
  scopeId?: string
  scopeType?: string
  hidden?: boolean
}

@controller
export class CommandPalettePageElement extends HTMLElement {
  static TopResultThreshold = 6.5

  @attr isRoot = false
  @attr pageTitle = ''
  @attr queryText = ''
  @attr mode = ''
  @attr scopeId = ''
  @attr scopeType = ''

  octicons: OcticonSvgs = {}

  /*
    This constructor should only used for attribute setting.
    Other setup should be performed in `connectedCallback`.
    See https://github.github.io/catalyst/guide/anti-patterns/
  */
  constructor(providedProps: PageProps = {}) {
    super()

    const defaultProps = {title: '', queryText: '', mode: '', scopeId: '', scopeType: '', hidden: false}
    const props = {
      ...defaultProps,
      ...providedProps
    }

    this.pageTitle = props.title
    this.queryText = props.queryText
    this.mode = props.mode
    this.scopeId = props.scopeId
    this.scopeType = props.scopeType
    this.hidden = props.hidden
  }

  // all of the providers present in the document
  documentProviders: ProviderElement[]
  tryDefaultSelection = true

  // a cache of Items organized by query path
  items: {
    [queryPath: string]: Item[]
  } = {}

  get commandPalette(): CommandPalette {
    return this.closest<CommandPalette>('command-palette')!
  }

  // Get all of the provider elements for this page
  get providers(): ProviderElement[] {
    this.documentProviders = [...this.serverDefinedProviderElements, ...this.clientDefinedProviderElements]

    if (!this.documentProviders) return []

    return this.documentProviders
  }

  get serverDefinedProviderElements(): ServerDefinedProviderElement[] {
    const providerElements =
      this.commandPalette.querySelectorAll<ServerDefinedProviderElement>('server-defined-provider')
    return Array.from(providerElements)
  }

  get clientDefinedProviderElements(): ClientDefinedProviderElement[] {
    const providerElements =
      this.commandPalette.querySelectorAll<ClientDefinedProviderElement>('client-defined-provider')
    return Array.from(providerElements)
  }

  get prefetchedProviders(): PrefetchedProvider[] {
    const providers = this.providers.map(providerElement => providerElement.provider)
    const prefetchedProviders = providers
      .map(provider => {
        if (provider instanceof PrefetchedProvider) {
          return provider
        } else {
          return null
        }
      })
      .filter(prefetchedProvider => prefetchedProvider !== null) as PrefetchedProvider[]

    return prefetchedProviders
  }

  get scope(): Scope {
    return {
      text: this.pageTitle,
      type: this.scopeType,
      id: this.scopeId,
      tokens: []
    }
  }

  get query(): Query {
    return new Query(this.queryText, this.mode, {scope: this.scope})
  }

  get selectedItem(): CommandPaletteItemElement | undefined {
    return this.findSelectedElement()
  }

  set selectedItem(newSelection: CommandPaletteItemElement | undefined) {
    const currentlySelected = this.findSelectedElement()

    if (currentlySelected) {
      currentlySelected.selected = false
    }

    if (newSelection) {
      newSelection.selected = true
      this.selectedItemChanged(newSelection.item)
    }
  }

  // We're unable to use Catalyst targets here since the groups get rebuilt as new results come in
  get groups(): CommandPaletteItemGroupElement[] {
    return Array.from(this.querySelectorAll<CommandPaletteItemGroupElement>('command-palette-item-group'))
  }

  get visibleGroups(): CommandPaletteItemGroupElement[] {
    return this.groups.filter(g => !g.hidden)
  }

  get currentItems(): Item[] {
    const items = this.items[this.query.path]

    if (!items) return []

    if (this.query.isBlank()) {
      // use default sort order returned from server when no query is present
      return items.sort((a, b) => b.priority - a.priority)
    } else {
      return items.sort((a, b) => {
        const aScore = a.calculateScore(this.queryText)
        const bScore = b.calculateScore(this.queryText)

        // sort by score and then use priority as a tie-breaker
        return bScore - aScore || b.priority - a.priority
      })
    }
  }

  get firstItem(): CommandPaletteItemElement | undefined {
    const visibleGroups = this.visibleGroups

    if (visibleGroups.length > 0) {
      return visibleGroups[0].querySelector<CommandPaletteItemElement>('command-palette-item')!
    }
  }

  get shouldSetDefaultSelection(): boolean {
    const isSearching = this.query.isPresent()
    // TODO: account for scope presence here as well
    return this.tryDefaultSelection && isSearching
  }

  findSelectedElement(): CommandPaletteItemElement | undefined {
    return this.querySelector<CommandPaletteItemElement>('command-palette-item[data-selected="true"]')!
  }

  findGroup(groupId: string): CommandPaletteItemGroupElement {
    return this.groups.find(g => g.groupId === groupId)!
  }

  navigate(indexDiff: number) {
    this.tryDefaultSelection = false

    const movingDownward = indexDiff > 0

    const scrollOptions = {
      behavior: 'smooth',
      block: 'nearest'
    } as ScrollIntoViewOptions

    if (this.selectedItem) {
      let next

      if (movingDownward) {
        next = this.selectedItem?.nextElementSibling as CommandPaletteItemElement
      } else {
        next = this.selectedItem?.previousElementSibling as CommandPaletteItemElement
      }

      if (next) {
        this.selectedItem = next
        this.selectedItem.scrollIntoView(scrollOptions)
      } else if (this.selectedItem) {
        // move to next/previous visible group
        const nextGroup = this.visibleGroups[this.calculateIndex(indexDiff)]
        nextGroup.scrollIntoView(scrollOptions)

        if (movingDownward) {
          this.selectedItem = nextGroup.firstItem
        } else {
          this.selectedItem = nextGroup.lastItem
        }
      }
    } else {
      this.selectedItem = this.firstItem
    }
  }

  /**
   * Calculate a valid index by adding a number (positive or negative). If the
   * index goes out of bounds, it is moved into bounds again.
   *
   * For example, if you have 3 items and the current index is 1.
   * - When you pass 1, it will return 2.
   * - When you pass 2, it will return 0.
   * - When you pass -2, it will return 2.
   *
   * JavaScript modulo operator doesn't handle negative numbers the same as
   * positive numbers so we have to do some additional work in the last line.
   *
   * @param indexDiff a positive or negative number
   * @returns new index (always in bound)
   */
  calculateIndex(indexDiff: number) {
    let currentIndex = this.visibleGroups.findIndex(group => group.groupId === this.selectedItem?.item.group)

    if (this.findGroup(CommandPaletteItemGroupElement.topGroupId).firstItem === this.selectedItem) {
      currentIndex = 0
    }

    const newIndexUnbounded = currentIndex + indexDiff
    const length = this.visibleGroups.length
    return ((newIndexUnbounded % length) + length) % length
  }

  async prefetch() {
    await Promise.all(
      this.prefetchedProviders.map(async provider => {
        if (!provider.scopeMatch(this.query)) return

        await provider.prefetch(this.query)
        if (provider.octicons && provider.octicons.length > 0) {
          this.cacheIcons(provider.octicons, true)
        }
      })
    )

    this.fetch(this.prefetchedProviders.map(provider => provider.element))
  }

  /*
    This is largely a re-implementation of `fetchProviderData` from `CommandPaletteElement`,
    the intention being that this method will relace that method,
    which will be deleted at some point.
  */
  async fetch(providers: ProviderElement[] = this.providers) {
    if (this.hidden) return

    const query = this.query

    await Promise.all(
      providers.map(async providerElement => {
        const provider = providerElement.provider

        if (!provider.enabledFor(query)) return

        const data = await providerElement.fetchWithDebounce(query, false)

        if (data) {
          if (data.error) {
            // TODO: show error tips
          }

          if (data.octicons && data.octicons.length > 0) {
            this.cacheIcons(data.octicons, true)
          }

          if (data.results.length > 0) {
            this.addItems(query, data.results)
          }
        }
      })
    )

    this.renderCurrentItems()
  }

  addItems(query: Query, items: Item[]) {
    if (!(query.path in this.items)) {
      this.items[query.path] = []
    }

    this.items[query.path].push(...items)
  }

  cacheIcons(octicons: Octicon[], dispatchEvent = false) {
    for (const octicon of octicons) {
      this.octicons[octicon.id] = octicon.svg
    }

    if (dispatchEvent) {
      this.dispatchEvent(new CustomEvent('command-palette-page-octicons-cached', {bubbles: true, detail: {octicons}}))
    }
  }

  buildGroups(items: Item[]) {
    const groupIds = ['top', ...new Set(items.map(item => item.group))]
    const querySelector = groupIds.map(id => `command-palette-item-group[data-group-id="${id}"]`).join(',')
    const groupList = document.querySelectorAll<CommandPaletteItemGroupElement>(querySelector)

    for (const groupElement of groupList) {
      if (!this.querySelector(`command-palette-item-group[data-group-id="${groupElement.groupId}"]`)) {
        this.append(groupElement.cloneNode(false))
      }
    }
  }

  // Semi-hacky way to remove the top border from the first visible group,
  // but ensure that the other groups still have their top borders.
  setGroupBorders() {
    if (this.visibleGroups.length > 0) {
      this.visibleGroups[0].classList.remove('border-top')

      for (const group of this.visibleGroups) {
        const i = this.visibleGroups.indexOf(group)

        if (i === 0) {
          group.classList.remove('border-top')

          if (group.header) {
            group.classList.remove('py-2')
            group.classList.add('mb-2', 'mt-3')
          }
        } else {
          group.classList.add('border-top')

          if (group.header) {
            group.classList.remove('mb-2', 'mt-3')
            group.classList.add('py-2')
          }
        }
      }
    }
  }

  renderCurrentItems() {
    this.reset()

    const currentItems = this.currentItems
    const renderedItemIds: string[] = []

    if (currentItems && currentItems.length > 0) {
      this.buildGroups(currentItems)

      for (const item of currentItems) {
        if (renderedItemIds.indexOf(item.id) >= 0) continue

        // add the item priority for top result consideration
        const itemScore = item.calculateScore(this.queryText) + item.priority
        let groupId = item.group

        if (currentItems.indexOf(item) === 0 && itemScore > CommandPalettePageElement.TopResultThreshold) {
          groupId = 'top'
        }

        const itemElement = item.render(false, this.queryText)
        const group = this.querySelector<CommandPaletteItemGroupElement>(
          `command-palette-item-group[data-group-id="${groupId}"]`
        )!

        if (!group.atLimit) {
          group.push(itemElement)
          renderedItemIds.push(item.id)

          if (item.icon) {
            if (item.icon.type === 'octicon') {
              const iconSvg = this.octicons[item.icon.id!]
              const fallbackIconSvg = this.octicons['dash-color-fg-muted']

              itemElement.renderOcticon(iconSvg || fallbackIconSvg)
            } else if (item.icon.type === 'avatar') {
              itemElement.renderAvatar(item.icon.url!, item.icon.alt!)
            }
          } else {
            itemElement.iconElement.hidden = true
          }

          itemElement.addEventListener('mousemove', e => {
            const moved = e.movementX !== 0 || e.movementY !== 0

            if (moved && this.selectedItem?.itemId !== itemElement.itemId) {
              this.tryDefaultSelection = false
              this.selectedItem = itemElement
            }
          })
        }
      }

      if (this.shouldSetDefaultSelection) {
        this.selectedItem = this.firstItem
      }

      this.setGroupBorders()
    }
  }

  selectedItemChanged(item: Item) {
    const event = new CustomEvent('selectedItemChanged', {
      bubbles: true,
      cancelable: true,
      detail: {
        item,
        isDefaultSelection: this.tryDefaultSelection
      }
    })

    return this.dispatchEvent(event as CustomEvent)
  }

  // Called when a previously hidden page is re-shown
  reactivate() {
    this.hidden = false
    this.tryDefaultSelection = true
    this.queryText = ''
    this.prefetch()
  }

  reset() {
    this.tryDefaultSelection = true
    this.innerHTML = ''
  }

  connectedCallback() {
    this.classList.add('rounded-bottom-2')
    this.setAttribute('data-targets', 'command-palette-page-stack.pages')
    this.setAttribute('style', `max-height:400px;`) // TODO: make this computed & animated
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    // automatically fetch any time the mode or query string changes
    const refreshTriggers = ['data-mode', 'data-query-text']

    if (oldValue !== newValue && refreshTriggers.includes(name)) {
      this.fetch()
    }
  }
}
